var $first = $('.reviewItem:first'),
    $last = $('.reviewItem:last');

let $reveal = $('.reveal');

var scrolling = false;

var previousScroll = 0;




// make the bad image appear under the good one

$reveal.draggable({
    axis: 'x',
    containment: 'parent',
    iframeFix: true,
    refreshPositions: true,
    drag: function () {
        var position = $(this).position();
        var parentWidth = $(this).parent().width();
        var width = (position.left / parentWidth) * 100
        $('.featured').width(width + '%');
    }
});

$(window).resize(function () {
    $reveal.css('left', $('.featured').width() + 'px')
});


// direct browser to top right away
if (window.location.hash)
    scroll(0, 0);
// takes care of some browsers issue
setTimeout(function () { scroll(0, 0); }, 1);

$(function () {
    //your current click function
    $('.smoothScroll').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: $($(this).attr('href')).offset().top + 'px'
        }, 1500, 'swing');

        $('.menuOverlay').removeClass('showMenuOverlay');
        $('.indexMenu .menuOpenButton').addClass('showMenuClose');
    });



    // if we have anchor on the url (calling from other page)
    if (window.location.hash) {
        // smooth scroll to the anchor id

            $('html,body').animate({
                scrollTop: $(window.location.hash).offset().top + 'px'
            }, 1000, 'swing');
    }
});




let app = {};

app.preload = function() {
    setTimeout(() => {
        $("body").removeClass("preload");
    },100);
}

// makes image appear half way showing on load
app.setImage = function() {
    $reveal.css('left', '50%');
    $('.featured').css('width', '50%');
}

// open overlay menu
app.openMenuOverlay = function() {
    $('.menuOpenButton').on('click', function(e){
        e.preventDefault();
        $('.menuOverlay').addClass('showMenuOverlay');

        setTimeout(() => {
            $('.menuCloseButton').addClass('showMenuClose');
            $('.indexMenu .menuOpenButton').removeClass('showMenuClose');
        },400);
    })
}

//close overlay menu
app.closeMenuOverlay = function() {
    $('.menuCloseButton').on('click', function(e){
        e.preventDefault();
        $('.menuOverlay').removeClass('showMenuOverlay');

        setTimeout(() => {
            $('.menuCloseButton').removeClass('showMenuClose');
            $('.indexMenu .menuOpenButton').addClass('showMenuClose');
        }, 400);
    })
}

// opens the services like accordion
app.openCloseService = function() {
    $('.servicesOpenButton').on('click', function(e){
        e.preventDefault();

        
        

        let toOpen = this.parentElement.nextElementSibling;
        let scrollH = toOpen.scrollHeight;
        let opened = $(toOpen).hasClass('showService');

        if(!opened) {
            $(toOpen)
                .css('max-height', scrollH + 'px')
                .addClass('showService')
                .find('.hideServiceText').addClass('hide');
            $(this).addClass('serviceOpenOverlay')
        } else {
            $(toOpen)
                .css('max-height', 0)
                .removeClass('showService');
            $(this).removeClass('serviceOpenOverlay')
        }

    })
}



//opens products like accordion
app.openCloseProducts = function() {
    $('.openProductBlock').on('click', function(e){
        e.preventDefault();

        let toOpen = this.parentElement.nextElementSibling;
        let scrollH = toOpen.scrollHeight;
        let opened = $(this).parent().hasClass('productOpen');

        if (!opened) {
            $(toOpen)
                .css('max-height', scrollH + 'px');
            $(this).parent().addClass('productOpen');
            
        } else {
            $(toOpen)
                .css('max-height', 0);
            $(this).parent().removeClass('productOpen');
        }

    })
}

// view more text in service section
app.viewMoreService = function() {
    $('.moreInfoService').on('click', function(e){
        e.preventDefault();
        $(this).parent().find('.hideServiceText').fadeIn();
        $(this).addClass('hide');
    })
}


// switch between calibration photos
app.viewCali = function (){
    $('.smallImageButton').on('click', function(e){
        e.preventDefault();

        let newGoodPic = $(this).next().attr('src');
        let newBadPic = $(this).next().attr('data-src');
        let newTitle = $(this).attr('data-title');
        let newResult = $(this).attr('data-src');


        $('.bigCaliImage img').attr('src', newGoodPic);
        $('.featured').css('background', 'url(' + newBadPic + ')')
            .css('background-size', 'cover');
        
        $('.smallImageSelect').removeClass('smallImageSelect');
        $(this).addClass('smallImageSelect');

        $('.calibrationTitleText').text(newTitle);
        $('.calibrationResultText').text(newResult);


    })
}


app.nextButton = function() {
    $('.reviewNext').on("click", function (e) {
        e.preventDefault();
        
        var $next,
        $selected = $('.showReview');
        // get the selected item
        // If next li is empty , get the first
        $next = $selected.next('.reviewItem').length ? $selected.next('.reviewItem') : $first;

        $selected.addClass('reviewFade');
        setTimeout(() => {
            $selected.removeClass('showReview reviewFade')
        }, 400);
        

        setTimeout(() => {
            $next.removeClass('reviewFade')
                .addClass('showReview');
        }, 400);
    })
}

app.previousButton = function() {
    $('.reviewPrevious').on("click", function (e) {
        e.preventDefault();
        var $prev,
            $selected = $('.showReview');
            

        // If prev li is empty , get the last
        $prev = $selected.prev('.reviewItem').length ? $selected.prev('.reviewItem') : $last;

        $selected.addClass('reviewFade');
        setTimeout(() => {
            $selected.removeClass('showReview reviewFade')
        }, 400);

        console.log($selected);
        
        $prev.addClass('reviewFade');

        setTimeout(() => {
            $prev.removeClass('reviewFade')
                .addClass('showReview');
        },400);
    });

}

app.submitForm = () => {
    let height = $('form').css('height');

    $("#form").submit(function (e) {
        e.preventDefault();

        
        
        
        let $form = $('#form');
        $.post($form.attr("action"), $form.serialize()).then(function () {
            $('.formBody').addClass('hideForm');
            $('.formSubmitted').removeClass('hideForm')
            .addClass('fadeIn');

            //find initial height of formSubmitted
            let smallHeight = $('.formSubmitted').css('height');

            $('.formSubmitted').css('height', height);

            setTimeout(() => {
                $('.formSubmitted').removeClass('fadeIn').css('height', smallHeight);
            }, 200)
        });
    });
}





app.init = function() {
    app.preload();
    app.openMenuOverlay();
    app.closeMenuOverlay();
    app.openCloseService();
    app.viewMoreService();
    app.openCloseProducts();
    app.setImage();
    app.viewCali();
    app.nextButton();
    app.previousButton();
    app.submitForm();
}

$(function () {
    app.init();
});



